import React from "react";
import { connect } from "react-redux";
import { getTokens, fetchTokensFromRefreshAsync } from "../../app/actions/tokens";
import { withRouter } from "react-router-dom";

class TokenManager extends React.Component {
    constructor(props) {
        super(props);
        this.refreshTimer = null;
    }
    componentDidMount() {
        this.props.getTokens();
    }
    render() {
        const refresh = () => {
            if (this.props.tokens.refreshToken) {
                this.props.refreshTokens(this.props.tokens.refreshToken.encoded);
            }
        };
        if (this.props.tokens.accessToken) {
            const msToExpiry = this.props.tokens.accessToken.decoded[1].exp * 1000 - new Date().getTime();
            if (this.refreshTimer != null) {
                clearTimeout(this.refreshTimer);
                this.refreshTimer = null;
            }
            this.refreshTimer = setTimeout(() => { refresh(); }, (msToExpiry - 30000))
        }
        return <div>
            {this.props.children}
        </div>;
    }
}

const mapStateToProps = (state = {}) => {
    return {
        "tokens": state.tokens
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        "getTokens": (code) => {
            dispatch(getTokens());
        },
        "refreshTokens": (refreshToken) => {
            dispatch(fetchTokensFromRefreshAsync(refreshToken))
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TokenManager));

