import Subscription from "./subscription";
import Ajv from "ajv";
import addFormats from "ajv-formats";

const ajv = new Ajv({ "allErrors": true });
addFormats(ajv);

class Profile {

    /**
     * Default constructor with copy
     * @param {JSON} item copy source
     */
    constructor(item = null) {
        this.email = item ? item.email : "";
        this.fullname = item ? item.fullname : "";
        this.displayname = item ? item.displayname : "";
        this.userid = item ? item.userid : "";
        this.subscription = item ? item.subscription : null;
        this.imageurl = item ? item.imageurl : "";
    }
}

Profile.schema = {
    "type": "object",
    "properties": {
        "email": {
            "type": "string",
            "format": "email"
        },
        "fullname": {
            "type": "string"
        },
        "displayname": {
            "type": "string"
        },
        "userid": {
            "type": "string"
        },
        "imageurl": {
            "type": "string",
            "format": "uri",
            "pattern": "^http"
        },
        "subscription": Subscription.schema
    },
    "required": ["email", "fullname", "displayname"]
};

Profile.validate = (data) => {
    const validate = ajv.compile(Profile.schema);
    validate(data);
    const errors = validate.errors || [];
    return errors;
}
export default Profile;