import Program from "../models/program";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const saveProgramAsync = createAsyncThunk(
    "programs/put",
    async (program, { rejectWithValue }) => {
        const dataErrors = Program.validate(program);
        if (dataErrors && dataErrors.length > 0) {
            rejectWithValue({
                "data": null,
                "validationErrrors": dataErrors
            });
        }
        const accessToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).accessToken.encoded;
        const idToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).idToken.encoded;
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = { "data": program };
        const config = {
            "method": "PUT",
            "url": `https://${apiBackendHost}/${env}/userdata/programs`,
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${accessToken}`,
                "x-id-token": idToken
            },
            "data": data
        };
        try {
            const response = await axios(config);
            return {
                "data": response.data,
                "status": response.status
            };
        }
        catch (err) {
            return {
                "data": err.response.data,
                "status": err.response.status
            };
        }
    }
)

export const saveNewProgramAsync = createAsyncThunk(
    "programs/post",
    /**
     * Save new program
     * @param {Program} program program document
     * @returns {object} state update
     */
    async (program, { rejectWithValue }) => {
        const dataErrors = Program.validate(program);
        if (dataErrors && dataErrors.length > 0) {
            rejectWithValue({
                "data": null,
                "validationErrrors": dataErrors
            });
        }
        const accessToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).accessToken.encoded;
        const idToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).idToken.encoded;
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = { "data": program };
        const config = {
            "method": "POST",
            "url": `https://${apiBackendHost}/${env}/userdata/programs`,
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${accessToken}`,
                "x-id-token": idToken
            },
            "data": data
        };
        try {
            const response = await axios(config);
            return {
                "data": response.data,
                "status": response.status
            };
        }
        catch (err) {
            return {
                "data": err.response.data,
                "status": err.response.status
            };
        }
    });

export const fetchProgramsAsync = createAsyncThunk(
    "programs/get",
    async () => {
        const accessToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).accessToken.encoded;
        const idToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).idToken.encoded;
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = JSON.stringify({
        });
        const config = {
            "method": "GET",
            "url": `https://${apiBackendHost}/${env}/userdata/programs`,
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${accessToken}`,
                "x-id-token": idToken
            },
            "data": data
        };
        try {
            const response = await axios(config);
            return {
                "data": response.data,
                "status": response.status
            };
        }
        catch (err) {
            return {
                "data": err.response.data,
                "status": err.response.status
            };
        }
    });

const initialState = {
    "edit": {
        "document": null,
        "errors": []
    },
    "errors": {},
    "list": [],
    "new": {
        "document": null,
        "errors": []
    },
    "selected": null
};

export const programsSlice = createSlice(
    {
        "name": "programs",
        initialState,
        "reducers": {
            "cancelEdit": (state) => {
                state.edit.document = null;
                state.edit.errors = [];
            },
            "cancelNew": (state) => {
                state.new.document = null;
                state.new.errors = [];
            },
            "createNew": (state) => {
                state.new.document = Object.assign({}, new Program());
                state.new.errors = [];
                state.edit.document = undefined;
            },
            "updateEdit": (state, action) => {
                // state.edit.document = action.payload;
            },
            "updateNew": (state, action) => {
                // state.new.document = action.payload;
            },
            "addErrorsEdit": (state, action) => {
                state.edit.errors = action.payload;
            },
            "addErrorsNew": (state, action) => {
                state.new.errors = action.payload;
            },
            "clearErrorsNew": (state) => {
                state.new.errors = [];
            },
            "selectProgram": (state, action) => {
                state.edit.document = action.payload;
                state.edit.errors = [];
                state.new.document = null;
                state.new.errors = [];
            }
        },
        "extraReducers": (builder) => {
            builder.addCase(fetchProgramsAsync.pending, (state) => {
                state.edit.document = null;
                state.new.document = null;
            });
            builder.addCase(fetchProgramsAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 200) {
                    state.list = payload.data.data.map((program) => { return Object.assign({}, program) });
                }
                else {
                    state.errors = [payload.data.error];
                }
            });
            builder.addCase(fetchProgramsAsync.rejected, (state, action) => {
                state.errors = [action.errors];
            });
            builder.addCase(saveNewProgramAsync.pending, (state) => {
            });
            builder.addCase(saveNewProgramAsync.rejected, (state, action) => {
                state.errors = [action.errors];
            });
            builder.addCase(saveNewProgramAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 201) {
                    const newProgram = Object.assign({}, payload.data);
                    state.list.push(newProgram);
                    state.new.document = null;
                    state.new.errors = [];
                }
                else {
                    state.errors = [payload.data.error];
                }
            });
            builder.addCase(saveProgramAsync.pending, (state) => { });
            builder.addCase(saveProgramAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 200) {
                    const updatedProgram = Object.assign({}, payload.data);
                    const idx = state.list.findIndex((item) => { return updatedProgram.data.docid === item.docid });
                    if (idx > -1) {
                        state.list[idx] = updatedProgram.data;
                        state.edit.document = updatedProgram.data;
                        state.edit.errors = [];
                    }
                    state.edit.errors = [];
                }
                else {
                    state.errors = [payload.data.error];
                }
            });
            builder.addCase(saveProgramAsync.rejected, (state, action) => {
                state.errors = [action.errors];
            });
        }
    });

export const { cancelEdit, cancelNew, createNew, updateNew, addErrorsEdit, addErrorsNew, clearErrorsNew, selectProgram } = programsSlice.actions;