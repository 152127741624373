import { Alert, Button, Container, Col, Row } from "react-bootstrap";
import List from "./list";
import New from "./new";
import Program from "../../../app/models/program";
import React from "react";
import Rewards from "./rewards";
import View from "./edit";
import { connect } from "react-redux";
import { addErrorsEdit, addErrorsNew, cancelEdit, cancelNew, createNew, fetchProgramsAsync, saveNewProgramAsync, saveProgramAsync, selectProgram, updateNew } from "../../../app/actions/programs";
import "./manage.css";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state = {}) => {
    return {
        "programs": state.programs
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        "addErrorsEdit": (errors) => {
            dispatch(addErrorsEdit(errors));
        },
        "addErrorsNew": (errors) => {
            dispatch(addErrorsNew(errors));
        },
        "cancelEdit": () => {
            dispatch(cancelEdit());
        },
        "cancelNew": () => {
            dispatch(cancelNew());
        },
        "createNew": () => {
            dispatch(createNew());
        },
        "fetchPrograms": (params) => {
            dispatch(fetchProgramsAsync(params));
        },
        "saveNewProgram": (program) => {
            dispatch(saveNewProgramAsync(program));
        },
        "saveProgram": (program) => {
            dispatch(saveProgramAsync(program));
        },
        "selectProgram": (program) => {
            dispatch(selectProgram(program));
        },
        "updateNew": (update) => {
            dispatch(updateNew(update));
        }
    };
};

class ManagePrograms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "errors": [],
            "new": undefined,
            "selected": undefined
        }
    }
    onItemClick(item) {
        this.props.selectProgram(item);
    }
    onNewClick() {
        this.props.createNew();
    }
    onEditCancel() {
        this.props.cancelEdit();
    }
    onEditUpdate(document) {
        const errors = Program.validate(document);
        this.props.addErrorsEdit(errors);
    }
    onNewCancel() {
        this.props.cancelNew();
    }
    onEditSave(document) {
        this.props.saveProgram(document);
    }
    onNewSave(document) {
        this.props.saveNewProgram(document);
    }
    onNewUpdate(document) {
        const errors = Program.validate(document);
        this.props.addErrorsNew(errors);
    }
    componentDidMount() {
        this.props.fetchPrograms({});
    }
    render() {
        console.log(JSON.stringify(this.props.programs));
        return <div>
            <h1>Programs</h1>
            <div className="action-bar">
                <div className="action-item">
                    <Button onClick={() => { this.onNewClick(); }}>New</Button>
                </div>
            </div>
            <Container>
                <Row>
                    <Col xs={4}>
                        <List programs={this.props.programs.list} selected={this.props.programs.edit.document} onClick={(program) => { this.onItemClick(program); }}></List>
                    </Col>
                    <Col xs={8}>
                        <div>
                            {
                                this.props.programs.new.errors.map((error) => {
                                    return <Alert variant="danger" key={error.schemaPath}>{`${error.instancePath} ${error.message}`}</Alert>
                                })
                            }
                            {
                                this.props.programs.edit.errors.map((error) => {
                                    return <Alert variant="danger" key={error.schemaPath}>{`${error.instancePath} ${error.message}`}</Alert>
                                })
                            }
                        </div>
                        <div>
                            {
                                this.props.programs.new.document ?
                                    <New
                                        document={this.props.programs.new.document}
                                        errors={this.props.programs.new.errors}
                                        onCancel={() => { this.onNewCancel(); }}
                                        onChange={(evt) => { this.onNewUpdate(evt); }}
                                        onSave={(document) => { this.onNewSave(document) }}></New> :
                                    this.props.programs.edit.document ?
                                        <View document={this.props.programs.edit.document}
                                            errors={this.props.programs.edit.errors}
                                            onCancel={() => { this.onEditCancel(); }}
                                            onSave={(document) => { this.onEditSave(document) }}
                                            onChange={(document) => { this.onEditUpdate(document); }}></View>
                                        : undefined
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.programs.edit.document ? <Rewards></Rewards> : <div></div>}
                    </Col>
                </Row>
            </Container>
        </div >;
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagePrograms));