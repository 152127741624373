import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchTeamsAsync = createAsyncThunk(
    "teams/get",
    async () => {
        const accessToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).accessToken.encoded;
        const idToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).idToken.encoded;
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = JSON.stringify({
        });
        const config = {
            "method": "GET",
            "url": `https://${apiBackendHost}/${env}/userdata/teams`,
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${accessToken}`,
                "x-id-token": idToken
            },
            "data": data
        };
        try {
            const response = await axios(config);
            return {
                "data": response.data,
                "status": response.status
            };
        }
        catch (err) {
            return {
                "data": err.response.data,
                "status": err.response.status
            };
        }
    });
export const teamsSlice = createSlice(
    {
        "name": "teams",
        "initialState": {
            "teams": []
        },
        "reducers": {},
        "extraReducers": (builder) => {
            builder.addCase(fetchTeamsAsync.pending, (state) => {
                state.teams = [];
            });
            builder.addCase(fetchTeamsAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 200) {
                    state.teams = payload.data;
                }
                else {
                    state.error = payload.data.error;
                }
            });
        }
    });