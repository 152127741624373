import Profile from "../models/profile";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const saveProfileAsync = createAsyncThunk("profile/put", async(profile) => {
    const accessToken = window.localStorage
        && window.localStorage.getItem("tokens")
        && JSON.parse(window.localStorage.getItem("tokens")).accessToken.encoded;
    const idToken = window.localStorage
        && window.localStorage.getItem("tokens")
        && JSON.parse(window.localStorage.getItem("tokens")).idToken.encoded;
    const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
    const env = process.env.REACT_APP_CONFIG_ENV;
    const data = JSON.stringify(profile);
    const config = {
        "method": "PUT",
        "url": `https://${apiBackendHost}/${env}/userdata/profile`,
        "headers": {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "x-id-token": idToken
        },
        "data": data
    };
    try {
        const response = await axios(config);
        return {
            "data": response.data,
            "status": response.status
        };
    }
    catch (err) {
        return {
            "data": err.response.data,
            "status": err.response.status
        };
    }
});
export const fetchProfileAsync = createAsyncThunk(
    "profile/get",
    async () => {
        const accessToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).accessToken.encoded;
        const idToken = window.localStorage
            && window.localStorage.getItem("tokens")
            && JSON.parse(window.localStorage.getItem("tokens")).idToken.encoded;
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = JSON.stringify({
        });
        const config = {
            "method": "GET",
            "url": `https://${apiBackendHost}/${env}/userdata/profile`,
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${accessToken}`,
                "x-id-token": idToken
            },
            "data": data
        };
        try {
            const response = await axios(config);
            return {
                "data": response.data,
                "status": response.status
            };
        }
        catch (err) {
            return {
                "data": err.response.data,
                "status": err.response.status
            };
        }
    });

const initialState = {
    "edit": {
        "document": null,
        "errors": []
    },
    "errors": [],
    "current": Object.assign({}, new Profile())
};

export const profileSlice = createSlice(
    {
        "name": "profile",
        initialState,
        "reducers": {
            "addErrorsEdit": (state, action) => {
                state.edit.errors = action.payload;
            },
            "cancelEdit": (state) => {
                state.edit.errors = [];
            }
        },
        "extraReducers": (builder) => {
            builder.addCase(fetchProfileAsync.pending, (state) => {
                state.edit.document = null;
                state.edit.errors = [];
            });
            builder.addCase(fetchProfileAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 200) {
                    state.current = Object.assign({}, payload.data);
                    state.edit.document =  Object.assign({}, payload.data);
                    state.edit.errors = [];
                    state.errors = [];
                }
                else {
                    state.current = null;
                    state.errors = [payload.data.error];
                }
            });
            builder.addCase(fetchProfileAsync.rejected, (state, action) => {
                state.current = null;
                state.errors = [action.errors];
            });
            builder.addCase(saveProfileAsync.pending, (state) => {
                state.errors = [];
            });
            builder.addCase(saveProfileAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 200) {
                    state.current = Object.assign({}, payload.data);
                    state.edit.document = Object.assign({}, payload.data);
                    state.edit.errors = [];
                    state.errors = [];
                }
                else {
                    state.current = null;
                    state.errors = [payload.data.error];
                }
            });
            builder.addCase(saveProfileAsync.rejected, (state, action) => {
                state.errors = [action.errors];
            });
        }
    });

export const { addErrorsEdit, cancelEdit } = profileSlice.actions;