import React from "react";

export default function Landing() {
    return <div>
        <h1>Hello!</h1>
        <div className="section">
            <h2>Your Rewards</h2>
            <div>
                <p>
                    In this section, you'll find the rewards you've been awarded in programs you'll have onboarded.
                </p>
            </div>
            <div>
                <div>
                    <h3>For each program</h3>
                    <div>
                        <h4>Program A</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}