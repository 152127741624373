import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import React from "react";
import { getTokens } from "../../app/actions/tokens";
import { useSelector } from 'react-redux';

export function Navigation(props, context) {
    let tokens = {
        "payload": {
            "tokens": {
                "accessToken": null
            }
        }
    };
    const fetchedTokens = useSelector(getTokens);
    tokens = Object.assign({}, tokens, fetchedTokens);
    let loginNavLink = null;
    let logoutNavLink = null;
    let profileNavLink = null;
    let programsNavLink = null;
    let signupNavLink = null;
    if (!tokens.payload || !tokens.payload.tokens.accessToken) {
        const authUrl = process.env.REACT_APP_AUTH_HOST || "dev.auth.efog.ca";
        const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || "4qjjs8a4nvmumnauqvotr3blth";
        const redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || "http://172.25.228.22:3000/login/callback";
        const scopes = process.env.REACT_APP_AUTH_SCOPES || "email+openid+phone+profile";
        const loginUrl = `https://${authUrl}/login?client_id=${clientId}&response_type=code&scope=${scopes}&redirect_uri=${redirectUrl}`;
        loginNavLink = <Nav.Item>
            <Nav.Link href={loginUrl}>Login</Nav.Link>
        </Nav.Item>;
        signupNavLink = <Nav.Item>
            <LinkContainer to="/signup">
                <Nav.Link>Signup</Nav.Link>
            </LinkContainer>
        </Nav.Item>;
        logoutNavLink = null;
    }
    else {
        logoutNavLink = <Nav.Item>
            <LinkContainer to="/logout">
                <Nav.Link>Logout</Nav.Link>
            </LinkContainer>
        </Nav.Item>;
        profileNavLink = <Nav.Item>
            <LinkContainer to="/profile">
                <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
        </Nav.Item>;
        programsNavLink = <Nav.Item>
            <LinkContainer to="/programs">
                <Nav.Link>Programs</Nav.Link>
            </LinkContainer>
        </Nav.Item>;
    }
    return <Nav className="justify-content-center" activeKey="/">
        <Nav.Item>
            <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
            </LinkContainer>
        </Nav.Item>
        {profileNavLink}
        {programsNavLink}
        {loginNavLink}
        {logoutNavLink}
        {signupNavLink}
    </Nav>;
};