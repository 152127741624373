class Member {
    constructor() {
        this.name = "";
    }
}
Member.schema = {
    "type": "object",
    "properties": {}
}

export default Member;