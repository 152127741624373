import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import actions from "./index";
import atob from "atob";
import axios from "axios";
import request from "request";

function getTokensFromLocalStorage() {
    if (window.localStorage && window.localStorage.getItem("tokens")) {
        return JSON.parse(window.localStorage.getItem("tokens"));
    }
    return {
        "accessToken": null,
        "idToken": null,
        "refreshToken": null
    }
}
function doLogout() {
    const tokens = {
        "accessToken": null,
        "idToken": null,
        "refreshToken": null
    };
    setTokens(tokens);
    return tokens;
}
function setTokens(tokens) {
    if (window.localStorage) {
        window.localStorage.setItem("tokens", JSON.stringify(tokens));
    }
}
export const fetchTokensFromRefreshAsync = createAsyncThunk(
    "tokens/refreshTokens",
    async (refreshToken) => {
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || "http://localhost:3000/login/callback";
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = JSON.stringify({
            "refreshToken": `${refreshToken}`,
            "redirectUrl": redirectUrl
        });
        const config = {
            "method": "POST",
            "url": `https://${apiBackendHost}/${env}/tokens/refresh`,
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            "data": data
        };
        try {
            const response = await axios(config);
            return {
                "data": response.data,
                "status": response.status
            };
        }
        catch (err) {
            return {
                "data": err.response.data,
                "status": err.response.status
            };
        }
    }
)
export const fetchTokensAsync = createAsyncThunk(
    "tokens/fetchTokens",
    async (code) => {
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || "http://localhost:3000/login/callback";
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = JSON.stringify({
            "code": `${code}`,
            "redirectUrl": redirectUrl
        });
        const config = {
            "method": "POST",
            "url": `https://${apiBackendHost}/${env}/tokens`,
            "headers": {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            "data": data
        };
        try {
            const response = await axios(config);
            return {
                "data": response.data,
                "status": response.status
            };
        }
        catch (err) {
            return {
                "data": err.response.data,
                "status": err.response.status
            };
        }
    });
function decodeToken(token) {
    const split = token.split(".");
    return split.slice(0, Math.min(2, split.length)).map((token) => { return JSON.parse(atob(token)); })
}
export const tokenSlice = createSlice(
    {
        "name": "login",
        "initialState": {
            "idToken": null,
            "refreshToken": null,
            "accessToken": null,
            "error": null
        },
        "reducers": {
            "getTokens": (state) => {
                const tokens = getTokensFromLocalStorage();
                state.accessToken = tokens.accessToken;
                state.idToken = tokens.idToken;
                state.refreshToken = tokens.refreshToken;
            },
            "logout": (state) => {
                state = Object.assign(state, doLogout());
            }
        },
        "extraReducers": (builder) => {
            builder.addCase(fetchTokensAsync.pending, (state) => {
                state.error = null;
                state.idToken = null;
                state.refreshToken = null;
                state.accessToken = null;
            });
            builder.addCase(fetchTokensAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 200) {
                    state.idToken = { "encoded": payload.data.idToken, "decoded": decodeToken(payload.data.idToken) };
                    state.accessToken = { "encoded": payload.data.accessToken, "decoded": decodeToken(payload.data.accessToken) };
                    state.refreshToken = { "encoded": payload.data.refreshToken };
                    setTokens(state);
                }
                else {
                    state.error = payload.data.error;
                }
            });
            builder.addCase(fetchTokensFromRefreshAsync.pending, () => {
            });
            builder.addCase(fetchTokensFromRefreshAsync.fulfilled, (state, action) => {
                const payload = action.payload;
                if (payload.status === 200) {
                    state.idToken = { "encoded": payload.data.idToken, "decoded": decodeToken(payload.data.idToken) };
                    state.accessToken = { "encoded": payload.data.accessToken, "decoded": decodeToken(payload.data.accessToken) };
                    setTokens(state);
                }
                else {
                    state.error = payload.data.error;
                }
            });
        }
    }
);

/**
 * Fetches asynchroneously access, refresh and id tokens for code
 * @param {string} code authorization code to get tokens for
 * @returns {function} dispatch method
 */
export function fetchTokens(code) {
    return (dispatch) => {
        dispatch(
            {
                "type": actions.FETCHING_TOKENS
            }
        );
        const redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || "http://localhost:3000/login/callback";
        const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
        const env = process.env.REACT_APP_CONFIG_ENV;
        const data = JSON.stringify({
            "code": `${code}`,
            "redirectUrl": redirectUrl
        });
        const options = {
            "method": "POST",
            "url": `https://${apiBackendHost}/${env}/tokens`,
            "headers": {
                "Content-Type": ["application/json"],
                "Accept": "application/json"
            },
            "body": data
        };
        request(options, function (error, response) {
            if (error) {
                dispatch({
                    "type": actions.SHOW_ERROR,
                    "error": error
                });
                return;
            }
            const jsonBody = JSON.parse(response.body);
            if (response.statusCode === 200) {
                if (window.localStorage) {
                    window.localStorage.setItem("tokens", jsonBody);
                }
                dispatch({
                    "type": actions.FETCHED_TOKENS,
                    "tokens": response.body
                });
                const tokens = jsonBody;
                const idToken = tokens.idToken;
                const idTokenObject = JSON.parse(atob(idToken.split(".")[1]));
                dispatch({
                    "type": actions.FETCHED_IDENTITY,
                    "identity": idTokenObject
                });
            }
            else {
                dispatch({
                    "type": actions.SHOW_ERROR,
                    "error": jsonBody.error
                });
            }
        });
    };
}

export const { getTokens, logout } = tokenSlice.actions;