import Ajv from "ajv";
import {addFormats} from "ajv-formats";

class Subscription extends Document {

    /**
     * Default constructor with copy
     * @param {JSON} item source item
     */
    constructor(item = null) {
        super(item);
        this.starttime = item ? item.starttime : null;
        this.endtime = item ? item.endtime : null;
        this.sku = item ? item.sku : null;
    }
}

Subscription.schema = {
    "type": "object",
    "properties": {
        "starttime": {
            "type": "string",
            "format": "date-time"
        },
        "endtime": {
            "type": "string",
            "format": "date-time"
        }
    }
};

Subscription.validate = (data) => {
    const ajv = new Ajv({ "allErrors": true });
    addFormats(ajv);
    const validate = ajv.compile(Subscription.schema);
    validate(data);
    const errors = validate.errors || [];
    return errors;
}

export default Subscription;