import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkContainer } from "react-router-bootstrap";
import { ListGroup } from "react-bootstrap";
import React from "react";

export default function List(props) {
    console.log(JSON.stringify(props));
    return <div>
        <h2>List</h2>
        <ListGroup variant="flush" defaultActiveKey={props.selected ? props.selected.docid : null}>{
            props.programs.map && props.programs.map((program) => {
                const isActive = props.selected && props.selected.docid === program.docid;
                console.log(`item is active=${isActive}`);
                const children = <div>
                    {program.programName}
                    <LinkContainer className="action" href="#" to={`/userdata/programs/edit/${encodeURI(program.programName)}`}>
                        <FontAwesomeIcon icon="edit" />
                    </LinkContainer>
                    <LinkContainer className="action" href="#" to={`/userdata/programs/delete/${encodeURI(program.programName)}`}>
                        <span><FontAwesomeIcon icon="trash" /></span>
                    </LinkContainer>
                </div>;
                return <ListGroup.Item href={program.docid} onClick={(evt) => { evt.preventDefault(); props.onClick(program); }} action key={program.docid}>
                    {children}
                </ListGroup.Item>
            })
        }
        </ListGroup>
    </div>;
}