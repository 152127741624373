import Member from "./member";

class ProgramMember extends Member {
    constructor() {
        super();
        this.program = undefined;
    }
}
ProgramMember.schema = Object.assign( Member.schema, {
    "type": "object",
    "properties": {}
});
export default ProgramMember