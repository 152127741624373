import React from "react"

export default function Rewards(props) {
    return <div>
        <h2>Rewards</h2>
        <div>
            A reward is composed of:
            <ul>
                <li>Properties:
                    <ul>
                        <li>
                            Name
                        </li>
                        <li>
                            Identifier
                        </li>
                        <li>
                            Validity Period
                        </li>
                        <li>
                            Requires Opt-in
                        </li>
                        <li>
                            Event schema
                        </li>
                        <li>
                            Version
                        </li>
                    </ul>
                </li>
                <li>
                    Badge:
                    <ul>
                        <li>Image set
                            <ul>
                                <li>Constraints</li>
                            </ul>
                        </li>
                        <li>Attribution identifier</li>
                    </ul>
                </li>
                <li>Trigger Process:
                    <ul>
                        <li>JSON Path</li>
                        <li>
                            Trigger type:
                            <ul>
                                <li>Trigger once after count</li>
                                <li>Trigger every time</li>
                                <li>Trigger every range</li>
                                <li>Trigger after threshold/time</li>
                            </ul>
                        </li>
                        <li>
                            Distribution:
                            <ul>
                                <li>Record but do not reward until member join</li>
                                <li>Automatically reward</li>
                                <li>Reward but wait until event</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>;
}