import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchTokensAsync } from "../../app/actions/tokens";

const mapStateToProps = (state = {}) => {
    console.log(`receiving update ${JSON.stringify(state.token)}`);
    return {
        "tokens": state.tokens
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        "fetchTokens": (code) => {
            dispatch(fetchTokensAsync(code));
        }
    };
};

class LoginCallback extends Component {
    constructor(props) {
        super(props);
        const { code } = props;
        this.code = code;
    }
    componentDidMount() {
        if (this.code) {
            this.props.fetchTokens(this.code);
        }
    }
    render() {
        const error = this.props.tokens.error ? <Alert variant="danger">
            An authentication error occured: {this.props.tokens.error}
        </Alert> : null;
        const defaultContent = this.props.tokens && this.props.tokens.accessToken ? <Redirect to="/"></Redirect> : <div>{error}<span>Finishing authentication...</span></div>;
        return (
            <div className="container">
                {defaultContent}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginCallback));