import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Signup from "../models/signup";

export const doSignupAsync = createAsyncThunk("signup/post", async (formData) => {
    const apiBackendHost = process.env.REACT_APP_CONFIG_APIBACKENDHOST;
    const env = process.env.REACT_APP_CONFIG_ENV;
    const data = JSON.stringify(formData);
    const config = {
        "method": "POST",
        "url": `https://${apiBackendHost}/${env}/signup`,
        "headers": {
            "Content-Type": "application/json",
            "Accept": "application/json"
        },
        "data": data
    };
    try {
        const response = await axios(config);
        return {
            "data": response.data,
            "status": response.status
        };
    }
    catch (err) {
        return {
            "data": err.response.data,
            "status": err.response.status
        };
    }
});

const initialState = {
    "edit": {
        "dirty": false,
        "document": { ...new Signup() },
        "formatErrors": [],
        "formatWarnings": [],
        "dataErrors": []
    },
    "submitted": {
        "document": null,
        "error": {
            "code": null,
            "message": null
        }
    }
};

export const signupSlice = createSlice({
    "name": "signup",
    initialState,
    "reducers": {
        "formUpdate": (state, action) => {

            state.edit.dirty = true;
            state.edit.document = Object.assign(state.edit.document, { ...action.payload });

            const validations = Signup.validateFormatErrors(state.edit.document);
            state.edit.formatErrors = validations.errors || [];
            state.edit.formatWarnings = validations.warnings || [];
            state.edit.dataErrors = Signup.validateDataErrors(state.edit.document) || [];
        }
    },
    "extraReducers": (builder) => {
        builder.addCase(doSignupAsync.pending, (state) => {
            state.submitted = {
                "document": null,
                "error": {
                    "code": null,
                    "message": null
                }
            };
            state.edit.formatErrors = [];
        })
        builder.addCase(doSignupAsync.fulfilled, (state, action) => {
            switch(action.payload.status) {
                case 200:
                    state.submitted.document = {...new Signup({ ...action.payload.data })};
                    state.submitted.error = {
                        "code": null,
                        "message": null
                    };
                    break;
                case 400:
                case 500:
                default:
                    state.submitted.error.message = action.payload.data.message;
                    state.submitted.error.code = action.payload.status;
                    break;
            }
        });
        builder.addCase(doSignupAsync.rejected, (state, action) => {
            state.edit.formatErrors = [action.formatErrors];
        })
    }
});

export const { formUpdate } = signupSlice.actions;