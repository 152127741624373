import Ajv from "ajv";
import IconSet from "./icon-set";
import ProgramMember from "./program-member";
import ProgramSponsor from "./program-sponsor";

const ajv = new Ajv({ "allErrors": true });
const schema = {
    "type": "object",
    "properties": {
        "programName": {
            "type": "string",
            "pattern": ".{5,}$"
        },
        "description": {
            "type": "string",
            "pattern": ".{10,140}$"
        },
        "sponsor": ProgramSponsor.schema,
        "members": {
            "type": "array",
            "items": ProgramMember.schema
        },
        "iconSet": IconSet.schema,
        "doctype": {
            "type": "string",
            "pattern": "^program$"
        }
    },
    "required": [
        "programName",
        "description",
        "doctype",
        "docid",
        "sponsor"
    ]
};
const validate = ajv.compile(schema);
class Program {
    constructor() {
        this.programName = "";
        this.description = "";
        this.sponsor = {};
        this.iconSet = {};
        this.members = [{}];
        this.doctype = "program";
        this.docid = null;
    }
}
Program.validate = (data) => {
    validate(data);
    const errors = validate.errors || [];
    return errors;
}
Program.schema = schema;
export default Program;

