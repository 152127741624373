import { Navbar } from "react-bootstrap";
import React from 'react';
import logo from '../../logo.svg';

export function Header() {
    return <Navbar bg="light">
        <Navbar.Brand href="#home">
            <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
            />{' '}
                Rewardly
            </Navbar.Brand>
    </Navbar>;
}