import Ajv from "ajv";
import addFormats from "ajv-formats";

const ajv = new Ajv({ "allErrors": true });
addFormats(ajv);

class Signup {
    constructor(item = {}) {
        this.email = item.email || null;
        this.password = item.password || null;
        this.confirmPassword = item.confirmPassword || null;
        this.fullname = item.fullname || "";
        this.displayname = item.displayname || "";
        this.termsAndConditionsAccept = item.termsAndConditionsAccept || false;
        this.contactEmail = item.contactEmail || null;
        this.contactEmailVisible = item.contactEmailVisible || false;
    }
}
Signup.schema = {
    "type": "object",
    "properties": {
        "email": {
            "type": "string",
            "format": "email"
        },
        "password": {
            "type": "string",
            "pattern": "^.{8,}"
        },
        "confirmPassword": {
            "type": "string",
            "pattern": "^.{8,}"
        },
        "fullname": {
            "type": "string",
            "pattern": "^.{1,}"
        },
        "displayname": {
            "type": "string"
        },
        "contactEmail": {
            "format": "email"
        },
        "contactEmailVisible": {
            "type": "boolean"
        },
        "termsAndConditionsAccept": {
            "type": "boolean"
        }
    },
    "required": ["email", "password", "confirmPassword", "fullname"]
}
Signup.formatErrors = {
    "/fullname": "fullname",
    "/email": "email",
    "/password": "password",
    "/confirmPassword": "confirmPassword"
}
Signup.dataErrors = {
    "PASSWORDS_MISMATCH": "PASSWORDS_MISMATCH",
    "TERMSANDCONDITION_NOT_ACCEPTED": "TERMSANDCONDITION_NOT_ACCEPTED"
}

Signup.validateFormatErrors = (data) => {
    const validate = ajv.compile(Signup.schema);
    validate(data);
    const validations = validate.errors || [];
    console.log(JSON.stringify(validations));
    const errors = validations.filter((err) => { return Signup.formatErrors[err.instancePath]; }) || [];
    const warnings = validations.filter((err) => { return !Signup.formatErrors[err.instancePath]; }) || [];
    return {errors, warnings};
}

Signup.validateDataErrors = (data) => {
    const validations = [
        () => { if (data.password !== data.confirmPassword) { return Signup.dataErrors.PASSWORDS_MISMATCH; } return false; },
        () => { if (!data.termsAndConditionsAccept) { return Signup.dataErrors.TERMSANDCONDITION_NOT_ACCEPTED; } return false; }
    ];
    return validations.map((val) => { return val(); }).filter((predicate) => { return predicate; });
}
export default Signup;