import { Route, Switch } from "react-router-dom";
import { Header } from "./features/header";
import { Navigation } from "./features/navigation";
import Container from 'react-bootstrap/Container';
import React from 'react';
import TokenManager from "./features/token-manager";
import routes from "./app/routes/routes";
import './App.css';

function App() {
  const routesMarkup = routes.map((route) => {
    return <Route key={route.path} {...route} />;
  });
  return (
    <Container className="p-3">
      <TokenManager>
        <Header></Header>
        <Navigation></Navigation>
        <Switch>
          {routesMarkup}
        </Switch>
      </TokenManager>
    </Container>
  );
}

export default App;
