import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";

export default function CognitoSignup(props) {
    return <Form>
        <Row>
            <Col xs={4}>
                <h3>The important stuff:</h3>
                <Form.Group controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control onChange={(evt) => { props.onChange(evt); }} type="email" placeholder="someone@example.com" />
                    <Form.Text className="text-muted">This email address is your unique identifier.</Form.Text>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control onChange={(evt) => { props.onChange(evt); }} type="password" placeholder="P@ssw0rd" />
                    <Form.Text className="text-muted">Please use a robust password. 😉</Form.Text>
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control onChange={(evt) => { props.onChange(evt); }} type="password" placeholder="P@ssw0rd" />
                    <Form.Text className="text-muted">Both password fields have to match.</Form.Text>
                </Form.Group>
                <Form.Group controlId="termsAndConditionsAccept">
                    <Form.Label>Agree to terms and conditions.</Form.Label>
                    <Form.Check onChange={(evt) => { props.onChange(evt); }} type="checkbox" />
                    <Form.Text className="text-muted">By signing up for this application you are explicitly agreeing the following terms and conditions. We value your privacy by enforcing the following privacy policy.</Form.Text>
                </Form.Group>
            </Col>
            <Col xs={5}>
                <h3>Please tell us more:</h3>
                <Form.Group controlId="fullname">
                    <Form.Label>Fullname</Form.Label>
                    <Form.Control onChange={(evt) => { props.onChange(evt); }} type="text" placeholder="Alice B. Carl" />
                    <Form.Text className="text-muted">This name won't be displayed.</Form.Text>
                </Form.Group>
                <Form.Group controlId="displayname">
                    <Form.Label>Displayed Name</Form.Label>
                    <Form.Control onChange={(evt) => { props.onChange(evt); }} type="text" placeholder="B Awesome" />
                    <Form.Text className="text-muted">This is going to be visible.</Form.Text>
                </Form.Group>
                <Form.Group controlId="contactEmail">
                    <Form.Label>Contact Email Address</Form.Label>
                    <Form.Control onChange={(evt) => { props.onChange(evt); }} type="email" placeholder="someone@example.com" />
                    <Form.Text className="text-muted">You can change the contact email address for future communications. However, all authentication related communications will use your signup email.</Form.Text>
                </Form.Group>
                <Form.Group controlId="contactEmailVisible">
                    <Form.Label>Contact Email Address Visibility</Form.Label>
                    <Form.Check onChange={(evt) => { props.onChange(evt); }} type="checkbox" />
                    <Form.Text className="text-muted">You can make your contact email visible... or not.</Form.Text>
                </Form.Group>
            </Col>
            <Col xs={3}>
                {props.formatErrors.map((error) => {
                    return <Alert variant="danger" key={error.schemaPath}>{`${error.instancePath} ${error.message}`}</Alert>
                })}
                {props.formatWarnings.map((warn) => {
                    return <Alert variant="warning" key={warn.schemaPath}>{`${warn.instancePath} ${warn.message}`}</Alert>
                })}
                {props.dataErrors.map((error) => {
                    return <Alert variant="danger" key={error}>{`${error}`}</Alert>
                })}
            </Col>
        </Row>
        <Row>
            <Col xs={3}>
                <Button disabled={!props.canSave} onClick={(evt) => { props.onSubmit(evt); }}>Submit</Button>
            </Col>
        </Row>
    </Form>
}

