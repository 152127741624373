
import ProfileForm from "./form";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { addErrorsEdit, cancelEdit, fetchProfileAsync, saveProfileAsync } from "../../../app/actions/profile";
import Profile from "../../../app/models/profile";

const mapStateToProps = (state = {}) => {
    return {
        "profile": state.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        "addErrorsEdit": (errors) => {
            dispatch(addErrorsEdit(errors));
        },
        "cancelEdit": () => {
            dispatch(cancelEdit());
        },
        "fetchProfile": () => {
            dispatch(fetchProfileAsync());
        },
        "saveProfile": (document) => {
            dispatch(saveProfileAsync(document));
        }
    };
};

class ManageProfile extends React.Component {
    componentDidMount() {
        this.props.fetchProfile();
    }
    onChange(document) {
        const errors = Profile.validate(document);
        this.props.addErrorsEdit(errors);
    }
    onSave(document) {
        this.props.saveProfile(document);
    }
    render() {
        return <div>
            <h1>User Profile</h1>
            <div>
                <ProfileForm
                    errors={this.props.profile.edit.errors}
                    profile={this.props.profile.edit.document || new Profile()}
                    onSave={(document) => { this.onSave(document); }}
                    onChange={(document) => { this.onChange(document); }}></ProfileForm>
            </div>
        </div>;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageProfile));