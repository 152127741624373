import { Button } from "react-bootstrap";
import JSONInput from "react-json-editor-ajrm";
import React, { useState } from "react";
import locale from "react-json-editor-ajrm/locale/en";

export default function New(props) {
    const [dirty, setDirty] = useState(false);
    const [document, setDocument] = useState({});
    const canSave = dirty && props.errors.length === 0;
    return <div>
        <h2>Edit</h2>
        <div>
            <JSONInput id="a_unique_id"
                theme="light_mitsuketa_tribute"
                placeholder={props.document}
                locale={locale}
                height="250px"
                width={500}
                onChange={(change) => {
                    setDirty(true);
                    setDocument(change.jsObject);
                    props.onChange(change.jsObject);
                }}></JSONInput>
        </div>
        <div className="action-bar">
            <div className="action-item">
                <Button disabled={!canSave} onClick={() => {
                    if (props.onSave) {
                        props.onSave(document);
                    }
                }}>Save</Button>
            </div>
            <div className="action-item">
                <Button onClick={() => {
                    if (props.onCancel) {
                        props.onCancel();
                    }
                }}>Cancel</Button>
            </div>
        </div>
    </div>;
}