import React from "react";
import { Col, Row } from "react-bootstrap";

export default function CognitoSignupComplete(props) {
    const authUrl = process.env.REACT_APP_AUTH_HOST || "dev.auth.efog.ca";
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || "4qjjs8a4nvmumnauqvotr3blth";
    const redirectUrl = process.env.REACT_APP_AUTH_REDIRECT_URL || "http://172.25.228.22:3000/login/callback";
    const scopes = process.env.REACT_APP_AUTH_SCOPES || "email+openid+phone+profile";
    const loginUrl = `https://${authUrl}/login?client_id=${clientId}&response_type=code&scope=${scopes}&redirect_uri=${redirectUrl}`;
    const loginNavLink = <a href={loginUrl}>here</a>;
    return <Row>
        <Col>
            <h1>Signup Successful!</h1>
            <div>
                You have successfully signed up for this app. In your {props.document.email} inbox, you will receive an email instructing you how to complete the registration process. <br />
                Once verified, you will be able to login {loginNavLink}.
            </div>
        </Col>
    </Row >;
}