import React from 'react';
import { getTokens, logout } from "../../app/actions/tokens";
import { useDispatch, useSelector } from 'react-redux';

export default function Logout() {
    const dispatch = useDispatch();
    const tokens = useSelector(getTokens);
    
    const authUrl = process.env.REACT_APP_AUTH_HOST || "dev.auth.efog.ca";
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || "4qjjs8a4nvmumnauqvotr3blth";
    const redirectUrl = process.env.REACT_APP_AUTH_LOGOUT_REDIRECT_URL || "http://localhost:3000/";
    const logoutUrl = `https://${authUrl}/logout?client_id=${clientId}&logout_uri=${redirectUrl}`;
    
    if (tokens.payload.tokens && tokens.payload.tokens.accessToken) {
        setTimeout(() => {
            dispatch(logout());
        }, 1000);
    }
    else {
        window.location = logoutUrl;
    }
    const redirect = <span>Logging out...</span>;
    return redirect;
}