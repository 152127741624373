/* eslint-disable import/no-anonymous-default-export */
import Landing from "../../features/landing";
import Login from "../../features/login";
import Logout from "../../features/logout";
import ManageProfile from "../../features/profile/details";
import ManagePrograms from "../../features/programs/manage";
import SignupPage from "../../features/profile/signup";

export default [
    {
        "component": Landing,
        "path": "/",
        "exact": true
    },
    {
        "component": Login,
        "path": "/login/:action",
        "exact": true
    },
    {
        "component": Logout,
        "path": "/logout",
        "exact": true
    },
    {
        "component": ManageProfile,
        "path": "/profile",
        "exact": true
    },
    {
        "component": ManagePrograms,
        "path": "/programs",
        "exact": true
    },
    {
        "component": SignupPage,
        "path": "/signup",
        "exact": true
    }
];