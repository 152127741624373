import JSONInput from "react-json-editor-ajrm";
import React, { useState } from "react";
import locale from "react-json-editor-ajrm/locale/en";
import { Alert, Button } from "react-bootstrap";

export default function ProfileForm(props) {
    const [dirty, setDirty] = useState(false);
    const [document, setDocument] = useState({});

    const onChange = (document) => {
        setDirty(true);
        setDocument(document);
        props.onChange(document);
    }
    
    const canSave = dirty && props.errors.length === 0;
    return <div>
        <h2>Edit{dirty ? " *" : ""}</h2>
        <div>
            {
                props.errors.map((error) => {
                    return <Alert variant="danger" key={error.schemaPath}>{`${error.instancePath} ${error.message}`}</Alert>
                })
            }
        </div>
        <JSONInput id="a_unique_id"
            theme="light_mitsuketa_tribute"
            placeholder={props.profile}
            locale={locale}
            height={500}
            onChange={(evt) => { onChange(evt.jsObject); }}
            width={500}></JSONInput>
            
        <div className="action-bar">
            <div className="action-item">
                <Button disabled={!canSave} onClick={() => {
                    if (props.onSave) {
                        setDirty(false);
                        props.onSave(document);
                    }
                }}>Save</Button>
            </div>
        </div>
    </div>;
}