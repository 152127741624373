import "./signup.css";
import CognitoSignup from "./cognito-signup";
import CognitoSignupComplete from "./cognito-signup-complete";
import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { doSignupAsync, formUpdate } from "../../../app/actions/signup";

const mapStateToProps = (state = {}) => {
    return {
        "signup": state.signup
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        "update": (keyValue) => {
            dispatch(formUpdate(keyValue));
        },
        "doSignup": (signupData) => {
            dispatch(doSignupAsync(signupData))
        }
    };
};

class SignupPage extends React.Component {
    componentDidMount() {
    }
    onChange(evt) {
        const kv = {};
        kv[evt.target.id] = evt.target.type === "checkbox"
            ? evt.target.checked
            : evt.target.value;
        this.props.update(kv);
    }
    onSubmit(evt) {
        this.props.doSignup(this.props.signup.edit.document);
    }
    render() {
        console.log(`${JSON.stringify(this.props.signup.edit)}`);
        const canSave = this.props.signup.edit.dirty && this.props.signup.edit.formatErrors.length === 0 && this.props.signup.edit.dataErrors.length === 0;
        console.log(canSave);
        const cognitoSignup = <CognitoSignup
            canSave={canSave}
            onChange={(evt) => { this.onChange(evt); }}
            onSubmit={(evt) => { this.onSubmit(evt); }}
            formatErrors={this.props.signup.edit.formatErrors}
            formatWarnings={this.props.signup.edit.formatWarnings}
            dataErrors={this.props.signup.edit.dataErrors}></CognitoSignup>
        const cognitoSignupComplete = <CognitoSignupComplete document={this.props.signup.edit.document}></CognitoSignupComplete>;
        const content = <Container fluid>
            <Row>
                <Col>
                    <h1>Sign Up</h1>
                    <div>
                        You are invited to sign up to our application so that you can start accumulating rewards and design your very own reward program!
                    </div>
                    <div>
                        You can use either the following methods to sign up:
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Using your username &amp; password</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    {this.props.signup.submitted.error.message && <Alert variant="danger">{`${this.props.signup.submitted.error.message}`}</Alert>}
                </Col>
            </Row>
            {!this.props.signup.submitted.document && cognitoSignup}
        </Container>;
        if (!this.props.signup.submitted.document)
            return content;
        return cognitoSignupComplete;
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupPage));