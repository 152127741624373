import { configureStore } from "@reduxjs/toolkit";
import { profileSlice } from "./actions/profile";
import { programsSlice } from "./actions/programs";
import { signupSlice } from "./actions/signup";
import { teamsSlice } from "./actions/teams";
import { tokenSlice } from "./actions/tokens";

export const store = configureStore({
  reducer: {
    "profile": profileSlice.reducer,
    "programs": programsSlice.reducer,
    "signup": signupSlice.reducer,
    "teams": teamsSlice.reducer,
    "tokens": tokenSlice.reducer
  },
});
